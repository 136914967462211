<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item :label="$t('等级设置.会员等级名称')" prop="levelName">
        <a-input v-model="form.levelName" :placeholder="$t('通用.输入.请输入')+$t('等级设置.会员等级名称')"/>
      </a-form-model-item>
<!--      <a-form-item :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">-->
<!--        <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>-->
<!--          <span slot="locale" slot-scope="text, record">-->
<!--           <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>-->
<!--          </span>-->
<!--          <div slot="name" slot-scope="text, record" >-->
<!--            <a-input v-model="record.name" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('等级设置.会员等级名称')" @blur.native.capture="validatorLocaleName"   />-->
<!--          </div>-->
<!--          <div slot="action" slot-scope="text, record">-->
<!--            <a-button type="primary" @click="getTranslation(record)">-->
<!--              {{$t('通用.按钮.翻译')}}-->
<!--            </a-button>-->
<!--          </div>-->
<!--        </a-table>-->
<!--        <span style="color: red;">{{ errorMessage }}</span>-->
<!--      </a-form-item>-->
     <a-form-model-item :label="$t('会员等级')" prop="sort">
        <a-input-number v-model="form.sort" :max="10" :min="1" :placeholder="$t('通用.输入.请输入')+$t('会员等级')"/>
      </a-form-model-item>
        <a-form-model-item :label="$t('升级所需经验')" prop="upgradeScore">
          <a-input v-model="form.upgradeScore" @change="limitPrice()"
                   :placeholder="$t('通用.输入.请输入')+$t('升级所需经验')"/>
        </a-form-model-item>

<!--      <a-form-model-item :label="this.$t('等级设置.等级图片')" prop="picture">-->
<!--        <a-upload-->
<!--          name="picture"-->
<!--          listType="picture-card"-->
<!--          :multiple="false"-->
<!--          :show-upload-list="false"-->
<!--          :preview="handlePreview"-->
<!--          :before-upload="beforeOssUpload"-->
<!--          :customRequest="coverImgUpload">-->
<!--          <img-->
<!--            v-if="form.picture"-->
<!--            :src="form.picture"-->
<!--            alt="picture"-->
<!--            style="height: 220px; width: 351px;"/>-->
<!--          <div v-else>-->
<!--            <a-icon :type="loading ? 'loading' : 'plus'"/>-->
<!--            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>-->
<!--          </div>-->
<!--        </a-upload>-->
<!--        <span>推荐尺寸 351 x 220</span>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item :label="this.$t('等级设置.等级图片')" prop="icon">-->
<!--        <a-upload-->
<!--          name="icon"-->
<!--          listType="picture-card"-->
<!--          :multiple="false"-->
<!--          :show-upload-list="false"-->
<!--          :preview="handlePreview"-->
<!--          :before-upload="beforeOssUpload"-->
<!--          :customRequest="coverIconUpload">-->
<!--          <img-->
<!--            v-if="form.icon"-->
<!--            :src="form.icon"-->
<!--            alt="icon"-->
<!--            style="height: 44px; width: 150px;"/>-->
<!--          <div v-else>-->
<!--            <a-icon :type="loading ? 'loading' : 'plus'"/>-->
<!--            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>-->
<!--          </div>-->
<!--        </a-upload>-->
<!--        <span>推荐尺寸 75 x 22</span>-->
<!--      </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getLevel, addLevel, updateLevel} from '@/api/user/level'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";

import Editor from '@/components/Editor'
import {translation} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
    Editor
  },
  data() {
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('等级设置.会员等级名称'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 表单参数
      form: {
        id: null,
        localeList: [],
        levelName: null,

        sort: null,
        picture: null,
        upgradeScore: null,
        discount: null,
        lotteryTimes: null,

        freightFree: null,

        birthdayGift: null,

        birthdayGiftContent: null,

        upgradeGift: null,

        upgradeGiftContent: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        levelName: [
          {required: true, message: this.$t('等级设置.会员等级名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        sort: [
          {required: true, message: this.$t('等级设置.会员等级排序，1最低，从小到大升级') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        upgradeScore: [
          {required: true, message: this.$t('等级设置.升级所需积分') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        discount: [
          {required: true, message: this.$t('等级设置.折扣') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        lotteryTimes: [
          {required: true, message: this.$t('等级设置.每日抽奖赠送的每天的抽奖次数') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        freightFree: [
          {required: true, message: this.$t('等级设置.是否免运费，1为是，0为否') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        birthdayGift: [
          {required: true, message: this.$t('等级设置.是否有生日礼包权益，1为有，0为没有') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        localeList: [],
        levelName: null,
        sort: null,
        picture: null,
        upgradeScore: null,
        discount: null,
        lotteryTimes: null,
        freightFree: null,
        birthdayGift: null,
        birthdayGiftContent: null,
        upgradeGift: null,
        upgradeGiftContent: null,
        createTime: null,
        remark: null,
      }

    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.levelName) {
        return
      }
      let data = {"text": this.form.levelName, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      })
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.name) {
          let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
          errorMessage += localeName + this.$t('通用.文本.不能为空')+';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage=''
      return true;
    },
    initLocale() {
      //初始化多语言
      let localeList = [];
      let i = 1 ;
      this.customDict.LocaleEnum.forEach(e=>{
        localeList.push({"id":i ,"locale":e.type,'name':null })
        i++;
      })
      this.form.localeList = localeList;
    },

    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },
    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'user'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'picture', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    coverIconUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'user'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'icon', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束

    limitPrice() {
      // console.log(i);
      let val = this.form.upgradeScore.replace(/^\D*([0-9]\d*)?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.upgradeScore = val
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.initLocale();
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLevel({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        let localeValid = this.validatorLocaleName();
        if (!localeValid) {
          return false;
        }
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLevel(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLevel(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
